import React, { useState, useRef } from "react"
import { Link, graphql } from "gatsby"
import { dateLarge } from "../utils/Utils"
import Page from "../components/page/Page"
import "./home.css"

export default props => {
  const [isPlay, setIsPlay] = useState(false)
  const videoRef = useRef(null)
  const play = () => {
    setIsPlay(true)
    videoRef.current.play()
  }
  const close = () => {
    setIsPlay(false)
    videoRef.current.pause()
  }
  const opinionEdges = props.data.allContentfulOpinion.edges
  return (
    <Page class={isPlay ? "home isPlay" : "home"}>
      <div className="hero">
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video
          className="teaser"
          preload="auto"
          width="640"
          height="360"
          autoPlay
          muted
          loop
        >
          <source
            src="https://videos.ctfassets.net/haczsvwoajn2/3tL3y04TAQGRzT9MplLs5a/ffa01b487d7c9d97267f733b763cd08a/nl-teaser.mp4"
            type="video/mp4"
          />
        </video>
        <button className="play" onClick={() => play()}>
          <i className="material-icons">play_arrow</i>
        </button>
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video
          ref={videoRef}
          className="intro"
          preload="auto"
          width="640"
          height="360"
          controls
        >
          <source
            src="https://videos.ctfassets.net/haczsvwoajn2/5SyKg2iexQDLvfyssiKyJB/02b642a1b712825ec0b8a850fc71af67/nl-intro.mp4"
            type="video/mp4"
          />
        </video>
        <button className="close" onClick={() => close()}>
          <i className="material-icons">close</i>
        </button>
      </div>
      <div className="welcome">
        He reunido aquí mi trabajo en la producción de ideas: la opinión
        política, la investigación académica y la reflexión sociológica que he
        llevado a cabo siempre bajo el compromiso intelectual y militante con la
        causa de la transformación del Perú en una sociedad más libre, justa y
        democrática.
      </div>
      <div className="list">
        <header className="list-header">
          <h1 className="title">Últimas publicaciones:</h1>
        </header>
        <main className="list-main">
          {opinionEdges.map((edge, i) => (
            <Link key={i} className="item" to={`/opinion/${edge.node.slug}`}>
              <h2 className="item-title">{edge.node.titulo}</h2>
              <div className="item-date">{dateLarge(edge.node.fecha)}</div>
              <div className="item-excerpt">
                {edge.node.texto.childMarkdownRemark.excerpt}
              </div>
            </Link>
          ))}
        </main>
      </div>
    </Page>
  )
}

export const opinionListHomeQuery = graphql`
  query opinionListHomeQuery {
    allContentfulOpinion(sort: { fields: fecha, order: DESC }, limit: 3) {
      edges {
        node {
          slug
          titulo
          fecha
          texto {
            childMarkdownRemark {
              excerpt
            }
          }
        }
      }
    }
  }
`
